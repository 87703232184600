
.custom-file-input:lang(en)~.custom-file-label::after{
    content: "Dosya Yükle";
}
.converter .nav{
    background: #f5f9fc;
    padding: 7px 0;
}
.dark .converter .nav{
     background: #1c2030;
}

.dark label{
    color: white;
}

.dark table th{
    color: white;
}
.dark .inprice{
    background-color: #2a2e39;
    color: white
}

.dark .converter .nav-link.active{
    color: #fff;
}

.dark button{
    color: #fff;
}

.dark .card {
    background: #1e222d;
}

.dark .card-body {
    color: #fff;
}


.container ul{
    margin-left: 1.5rem!important;
    margin-top: 0.25rem!important;
}
.container ul li {
    list-style: disc;
}

.company-intro  h1 {
    font-size: 72px;
    line-height: 1.8;
}

.information-section .card {
    border: 0
}

.card-header-information {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
}

.row-tradingview-widget .tradingview-widget-container {
    width: auto !important;
}
